import { ElementType, forwardRef, memo } from 'react';

export type SvgIconProps = {
  component?: string | ElementType;
  className?: string;
  viewBox?: string;
  width?: string;
  height?: string;
  children: JSX.Element | JSX.Element[];
};

type Ref = SVGElement;

const SvgIcon = forwardRef<Ref, SvgIconProps>(
  (
    {
      className,
      width = '1em',
      height = '1em',
      children,
      component: Component = 'svg',
      viewBox = '0 0 24 24',
      ...other
    },
    ref
  ) => {
    return (
      <Component
        focusable="false"
        viewBox={viewBox}
        className={className}
        ref={ref}
        width={width}
        height={height}
        {...other}
      >
        {children}
      </Component>
    );
  }
);

SvgIcon.displayName = 'CustomSvgIcon';

export default memo(SvgIcon);
