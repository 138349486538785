import { API_URL } from 'constants/routes';

import { FacetedResponse } from 'api_entities/types';
import { CancelTokenSource, fetcher } from 'api_entities/fetch';

import { Job, VacancyAdCard } from './types';
import { VacancyAd } from '../strapi/types';
import { mergeVacancyDataToVacancyAdCard } from './helpers';

export const vacanciesApi = {
  getJobs: async function (params?: URLSearchParams, source?: CancelTokenSource) {
    if (params) {
      params.append('validOnly', 'true');
    }

    const { data } = await fetcher.get<FacetedResponse<Job>>(API_URL.JOBS, {
      params,
      cancelToken: source?.token,
    });
    return data;
  },
  getBySlug: async function (slug?: string, validOnly?: boolean) {
    let searchParams = '';

    if (validOnly) {
      searchParams = `?validOnly=${validOnly}`;
    }

    const { data } = await fetcher.get<Job>(`${API_URL.VACANCIES}/slug/${slug}${searchParams}`);
    return data;
  },
  getSafeBySlugBulk: async function (slugs?: string[]) {
    if (!slugs || slugs?.length === 0) {
      return [];
    }

    const promises = slugs.map((slug) => vacanciesApi.getBySlug(slug));

    const resolvedPromises = await Promise.allSettled(promises);

    const wrappedResult = resolvedPromises.map((promise) => {
      if (promise.status === 'rejected') {
        console.error(promise.reason);

        return [];
      }

      return promise.value;
    });

    return wrappedResult.flat();
  },
  getVacanciesAdData: async function (vacancyAds: VacancyAd[]): Promise<VacancyAdCard[]> {
    const result: VacancyAdCard[] = [];

    if (!vacancyAds || vacancyAds.length === 0) {
      return result;
    }

    const slugs = vacancyAds.reduce((acc, vacancyAd) => {
      if (!vacancyAd.isExternal && vacancyAd?.slug) {
        acc.push(vacancyAd.slug);
      }

      return acc;
    }, [] as string[]);

    const vacancies = await vacanciesApi.getSafeBySlugBulk(slugs);

    for (const vacancyAd of vacancyAds) {
      if (vacancyAd.isExternal && vacancyAd.vacancyUrl) {
        result.push({
          isRecommended: vacancyAd.recommended,
          slug: '',
          title: vacancyAd.title || '',
          shortDescription: vacancyAd.shortDescription,
          companyName: vacancyAd.companyName,
          companyLogo: vacancyAd.companyLogo?.data?.attributes.url,
          imageUrl: vacancyAd?.image?.data?.attributes.url,
          employmentType: vacancyAd.employmentType ? [vacancyAd.employmentType] : [],
          salaryCurrency: 'EUR',
          salaryMin: vacancyAd.salaryMin,
          salaryMax: vacancyAd.salaryMax,
          salaryUnit: vacancyAd.salaryUnit,
          city: vacancyAd.city ? [vacancyAd.city] : [],
          vacancyUrl: vacancyAd.vacancyUrl,
          linkText: vacancyAd.linkText,
          openInNewWindow: vacancyAd.openInNewWindow,
          importantInfo: vacancyAd.importantInfo,
        });
      } else if (vacancyAd.slug) {
        const vacancy = vacancies.find(({ slug }) => slug === vacancyAd.slug);

        if (!vacancy) {
          continue;
        }

        const vacancyAdCard = mergeVacancyDataToVacancyAdCard(vacancyAd, vacancy);
        result.push(vacancyAdCard);
      }
    }

    return result;
  },
  getTraineeships: async function (params?: URLSearchParams, source?: CancelTokenSource) {
    const { data } = await fetcher.get<FacetedResponse<Job>>(API_URL.TRAINEESHIPS, {
      params,
      cancelToken: source?.token,
    });
    return data;
  },
  getInternships: async function (params?: URLSearchParams, source?: CancelTokenSource) {
    const { data } = await fetcher.get<FacetedResponse<Job>>(API_URL.INTERNSHIPS, {
      params,
      cancelToken: source?.token,
    });
    return data;
  },
  getVacancies: async function (params?: URLSearchParams, source?: CancelTokenSource) {
    const { data } = await fetcher.get<FacetedResponse<Job>>(API_URL.VACANCIES, {
      params,
      cancelToken: source?.token,
    });
    return data;
  },
  getAllJobs: async function (limit?: string) {
    const params = new URLSearchParams();
    params.append('limit', limit || '1000');
    const { data } = await fetcher.get<string[]>(`${API_URL.JOBS}/site_map`, {
      params: params,
      headers: {
        'x-token-token': process.env.NEXT_PUBLIC_SITEMAP_TOKEN || '',
      },
    });
    return data;
  },
  getAllInternships: async function () {
    const params = new URLSearchParams();
    params.append('limit', '10000');
    const { data } = await fetcher.get<string[]>(`${API_URL.INTERNSHIPS}/site_map`, {
      params: params,
      headers: {
        'x-token-token': process.env.NEXT_PUBLIC_SITEMAP_TOKEN || '',
      },
    });
    return data;
  },
  getAllTraineeships: async function () {
    const params = new URLSearchParams();
    params.append('limit', '10000');
    const { data } = await fetcher.get<string[]>(`${API_URL.TRAINEESHIPS}/site_map`, {
      params: params,
      headers: {
        'x-token-token': process.env.NEXT_PUBLIC_SITEMAP_TOKEN || '',
      },
    });
    return data;
  },
};
