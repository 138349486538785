import querystring from 'querystring';
import { COOKIE } from 'constants/cookie';

import { authApi } from 'api_entities/auth';
import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
  CancelTokenStatic,
} from 'axios';
import Cookies from 'js-cookie';

interface FetchInstance extends AxiosInstance {
  CancelToken: CancelTokenStatic;
  isCancel: (value: any) => boolean;
}

const isServer = typeof window === 'undefined';

const instance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API}/api`,
  transformRequest: (jsonData = {}, headers) => {
    // FormData is a web API, don't forget to check is it in web
    if (!isServer && jsonData instanceof FormData) {
      return jsonData;
    }

    return headers && headers['Content-Type'] === 'application/json'
      ? JSON.stringify(jsonData)
      : querystring.stringify(jsonData);
  },
}) as FetchInstance;

const onRequestSuccess = async (config: AxiosRequestConfig) => {
  const token = Cookies.get(COOKIE.ACCESS_TOKEN);
  if (token && config) {
    config.headers = Object.assign({}, config?.headers, { ['Authorization']: `Bearer ${token}` });
  }
  return config;
};

const onRequestError = (error: AxiosError) => {
  Promise.reject(error);
};

const onSuccess = (response: AxiosResponse): AxiosResponse => response;
const onError = async (error: AxiosError): Promise<any> => {
  const refreshToken = Cookies.get(COOKIE.REFRESH_TOKEN);
  if (error.response?.status === 401 && refreshToken) {
    const data = await authApi.refreshToken(refreshToken);
    if (data) {
      error.config.transformRequest = (obj = {}) => {
        return querystring.stringify(querystring.parse(obj));
      };
      return instance.request(error.config);
    }
  }
  return Promise.reject(error.response);
};

instance.interceptors.request.use(onRequestSuccess, onRequestError);
instance.interceptors.response.use(onSuccess, onError);
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export enum FETCH_STATUS {
  INIT = 'INIT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export { instance as fetcher };
export type { AxiosError as FetchError } from 'axios';
export type { CancelTokenSource } from 'axios';
