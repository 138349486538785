import type { NextRouter } from 'next/router';
import { format, formatDistanceToNow } from 'date-fns';
import { nl } from 'date-fns/locale';
import { UserProfile } from 'api_entities/user/types';
import { VacanciesAdSection, VacanciesAdSectionCards } from '../api_entities/strapi/types';
import { vacanciesApi } from '../api_entities/vacancies';
import { StudentCard } from '../api_entities/student/types';

export const isServer = (): boolean => typeof window === 'undefined';

export const getShowAllHref = (route: string, excludeQueries?: Array<string>) => (
  router: NextRouter
): string => {
  const params = new URLSearchParams();
  Object.entries(router.query).map(([key, value]) => {
    if (value instanceof Array) {
      value.forEach((element) => params.append(key, element));
    } else {
      value && params.append(key, value);
    }
  });
  excludeQueries?.forEach((query) => params.delete(query));
  return `${route}?${params}`;
};

export const removeFalsy = (obj: Record<string, any>) => {
  const newObj = {} as Record<string, string>;
  Object.keys(obj).forEach((prop) => {
    if (
      typeof obj[prop] === undefined ||
      obj[prop] === null ||
      (typeof obj[prop] === 'string' && obj[prop].length == 0)
    ) {
      return;
    }

    const value = obj[prop];

    newObj[prop] = typeof value !== 'boolean' ? value || '' : value;
  });
  return newObj;
};

export const validateSlug = (slug?: string | string[]): string => {
  // nextjs behaviour in chrome
  // https://github.com/vercel/next.js/discussions/13566
  if (!slug || slug === 'null' || slug === '[object Object]' || Array.isArray(slug)) {
    throw new Error('Invalid slug.');
  }

  return slug;
};

export const formatLocalDate = (date?: Date | string, dateFormat = 'dd MMM, uuuu'): string => {
  try {
    if (!date) {
      return '';
    }

    const _date = new Date(date);

    if (!_date.getTime()) {
      return '';
    }

    return format(_date, dateFormat, { locale: nl });
  } catch (e) {
    return '';
  }
};

export const formatDateToNow = (date: Date | string) => {
  try {
    if (!date) {
      return '';
    }

    const _date = new Date(date);

    if (!_date.getTime()) {
      return '';
    }

    return formatDistanceToNow(_date, { locale: nl });
  } catch (e) {
    return '';
  }
};

export const isProfileComplete = ({
  firstName,
  lastName,
  about,
  city,
  levelOfStudy,
  studySubject,
  startStudy,
  endStudy,
  onboardAt,
}: UserProfile): boolean => {
  return [
    firstName,
    lastName,
    about,
    city,
    levelOfStudy,
    studySubject,
    startStudy,
    endStudy,
    onboardAt,
  ].every(Boolean);
};

export const fixContetntfullImageUrls = (url: string) =>
  url.startsWith('//images.ctfassets.net') ? `https:${url}` : url;

export const parseImageUrl = (url?: unknown): { src?: string; height?: number; width?: number } => {
  if (typeof url === 'string') {
    return { src: fixContetntfullImageUrls(url) };
  }

  if (typeof url === 'object' && url !== null && 'src' in url) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return { src: fixContetntfullImageUrls(url.src), height: url.height, width: url.width };
  }

  return { src: undefined };
};

export const removeSearchParams = (url: string) => {
  const index = url.indexOf('?');

  if (index === -1) {
    return url;
  }

  return url.substring(0, index);
};

export const removeHash = (url: string) => {
  const index = url.indexOf('#');

  if (index === -1) {
    return url;
  }

  return url.substring(0, index);
};

export const createCanonicalUrl = (asPath: string, toLower?: boolean): string => {
  let url = removeSearchParams(`${process.env.NEXT_PUBLIC_HOME_URL}${asPath}`);
  url = removeHash(url);

  if (!toLower) {
    return url;
  }

  return url.toLowerCase();
};

export const getVacanciesAdSectionCards = async (
  data?: VacanciesAdSection
): Promise<VacanciesAdSectionCards | undefined> => {
  let vacanciesAdSectionCards: VacanciesAdSectionCards | undefined = undefined;
  if (data?.vacanciesAds?.length) {
    const vacancyAdCards = await vacanciesApi.getVacanciesAdData(data.vacanciesAds);
    vacanciesAdSectionCards = JSON.parse(
      JSON.stringify({
        title: data.title,
        vacancyAdCards,
      })
    );
  }

  return vacanciesAdSectionCards;
};

export const getUsernameFromStudent = (student: StudentCard) => {
  const usernameArray = [student.firstName, student.lastName].filter(Boolean);

  if (!usernameArray.length) {
    usernameArray.push(student.username ?? '');
  }

  return usernameArray.join(' ');
};

export const getTagsFromStudent = (student: StudentCard) => {
  const tags = [];

  if (student.levelOfStudy || student.studySubject) {
    tags.push([student.levelOfStudy, student.studySubject].filter(Boolean).join(' '));
  }
  if (student.onboardWhere) {
    tags.push(student.onboardWhere);
  }
  if (student.onboardIntro) {
    tags.push(student.onboardIntro);
  }
  if (student.onboardWork?.length) {
    tags.push(student.onboardWork.join(', '));
  }
  if (student.onboardEmployment) {
    if (student.onboardEmployment === 'Meer') {
      tags.push(student.onboardEmployment);
    } else {
      tags.push(`${student.onboardEmployment} uur p/w`);
    }
  }

  return tags;
};

const map: Record<string, string> = {
  Klantenservice: '/callcenter-personeel-inhuren',
  Horeca: '/horeca-personeel-inhuren',
  Verkoop: '/winkel-personeel-inhuren',
  Zorg: '/zorpersoneel-inhuren',
  IT: '/ict-personeel-inhuren',
  'Bezorger / Student Chauffeur': '/chauffeurs-inhuren',
  'Administratief / Financieel': '/administratief-personeel-inhuren',
  Beveiliging: '/beveiliging-ihuren',
  Bijles: '/bijles-inhuren',
  Retail: '/winkel-personeel-inhuren',
  Logistiek: '/logistiek-personeel-inhuren',
  Overig: '/inhuren',
};

export const mapOnboardWorkToSlug = (work: string): string => {
  return work in map ? map[work] : '/inhuren';
};

export const urlFormatter = (href = ''): string => {
  if (!href) {
    return '';
  }

  switch (true) {
    case href.startsWith('https://'): {
      return href;
    }

    case href.startsWith('www.'): {
      return `https://${href}`;
    }

    case href.startsWith('http://'): {
      return href.replace('http://', 'https://');
    }

    default: {
      console.warn('unexpected start for the URL', href);

      return href;
    }
  }
};
