import { VacancyAd } from '../strapi/types';
import { Job, VacancyAdCard } from './types';
import { APP_ROUTES } from '../../constants/routes';

export const mergeVacancyDataToVacancyAdCard = (
  vacancyAd: VacancyAd,
  vacancy: Job
): VacancyAdCard => {
  return {
    isRecommended: vacancyAd.recommended,
    slug: vacancy.slug,
    title: vacancy.title,
    description: vacancy.description,
    shortDescription: vacancy.shortDescription,
    companyName: vacancy.company?.title || vacancy.companyName,
    companyLogo: vacancy.company?.logoURL,
    imageUrl:
      vacancyAd?.image?.data?.attributes.url || vacancy?.photoURL || vacancy?.company?.photoURL,
    employmentType: vacancy.employmentType,
    salaryCurrency: vacancy.salaryCurrency,
    salaryMin: vacancy.salaryMin,
    salaryMax: vacancy.salaryMax,
    salaryUnit: vacancy.salaryUnit,
    city: vacancy.city,
    vacancyUrl: `${APP_ROUTES.ALL_VACANCIES}/${vacancy.slug}`,
    linkText: vacancyAd.linkText,
    openInNewWindow: vacancyAd.openInNewWindow,
    importantInfo: vacancyAd.importantInfo,
  };
};
