import { API_URL } from 'constants/routes';
import { COOKIE } from 'constants/cookie';

import Cookies from 'js-cookie';
import { fetcher } from 'api_entities/fetch';

import {
  LoginDTO,
  SignUpDTO,
  SignUpResult,
  LoginResult,
  RefreshPayload,
  ResetPasswordDTO,
  RestorePasswordDTO,
  VerifyEmailDTO,
  ResendVerifyEmailDTO,
} from './types';

const basicParams = {
  scope: 'basic',
  client_secret: 'web-secret',
  client_id: 'web',
};

export const authApi = {
  signUp: async function (payload: SignUpDTO) {
    const { data } = await fetcher.post<SignUpResult>(API_URL.SIGN_UP, payload);

    return data;
  },
  login: async (payload: LoginDTO) => {
    const { data } = await fetcher.post<LoginResult>(
      API_URL.LOGIN,
      { ...payload, ...basicParams, grant_type: 'password' },
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    Cookies.set(COOKIE.ACCESS_TOKEN, data.data.accessToken, {
      expires: 10,
      sameSite: 'lax',
      secure: process.env.NODE_ENV === 'production' ? true : false,
    });
    Cookies.set(COOKIE.REFRESH_TOKEN, data.data.refreshToken, {
      expires: 365,
      sameSite: 'lax',
      secure: process.env.NODE_ENV === 'production' ? true : false,
    });
    return data;
  },
  loginViaSocialNetwork: async (socialToken: string, socialType: 'google' | 'facebook') => {
    const { data } = await fetcher.post<LoginResult>(
      API_URL.LOGIN_VIA_SOCIAL_NETWORK,
      { ...basicParams, grant_type: 'social_network', socialToken, socialType },
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    Cookies.set(COOKIE.ACCESS_TOKEN, data.data.accessToken, {
      expires: 10,
      sameSite: 'lax',
      secure: process.env.NODE_ENV === 'production' ? true : false,
    });
    Cookies.set(COOKIE.REFRESH_TOKEN, data.data.refreshToken, {
      expires: 365,
      sameSite: 'lax',
      secure: process.env.NODE_ENV === 'production' ? true : false,
    });
    return data;
  },
  refreshToken: async (refreshToken: string) => {
    Cookies.remove(COOKIE.ACCESS_TOKEN);
    Cookies.remove(COOKIE.REFRESH_TOKEN);
    try {
      const { data } = await fetcher.post<RefreshPayload>(
        API_URL.REFRESH_TOKEN,
        { ...basicParams, grant_type: 'refresh_token', refresh_token: refreshToken },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      Cookies.set(COOKIE.ACCESS_TOKEN, data.data.accessToken, {
        expires: 10,
        sameSite: 'lax',
        secure: process.env.NODE_ENV === 'production' ? true : false,
      });
      Cookies.set(COOKIE.REFRESH_TOKEN, data.data.refreshToken, {
        expires: 365,
        sameSite: 'lax',
        secure: process.env.NODE_ENV === 'production' ? true : false,
      });
      return data;
    } catch {
      return null;
    }
  },
  forgotPassword: async function (payload: ResetPasswordDTO) {
    const { data } = await fetcher.post<void>(API_URL.FORGOT_PASSWORD, payload);
    return data;
  },
  restorePassword: async function (payload: RestorePasswordDTO) {
    const { data } = await fetcher.post<void>(API_URL.RESTORE_PASSWORD, payload);
    return data;
  },

  verifyEmail: async function (payload: VerifyEmailDTO): Promise<boolean> {
    try {
      const searchParams = new URLSearchParams();
      searchParams.append('email', payload.email);
      searchParams.append('token', payload.token);

      await fetcher.get<void>(`${API_URL.VERIFY_EMAIL}?${searchParams.toString()}`);
      return true;
    } catch (e) {
      return false;
    }
  },

  resendVerifyEmailToken: async function (payload: ResendVerifyEmailDTO) {
    await fetcher.post<void>(API_URL.VERIFY_EMAIL, payload);
  },
};
